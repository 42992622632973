import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { UIButton } from "@/components/ui";
import { SharedVerification, SharedWindow } from "@/components/shared";

import {
  CODE_MAX_LENGTH,
  ModalActionLabel,
  ModalIcon,
  ModalLabel,
  ModalTitle,
} from "@/shared/constants/constants";
import { ApplicationStoreRequestFactory } from "@/shared/repository/modules/application/factory";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIButton,
    SharedVerification,
    SharedWindow,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    isConfirm: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:isLoading": null,
    "update:isConfirm": null,
  },
  data() {
    return {
      code: "",
      userRepo: this.$projectServices.userRepo,
      walletRepo: this.$projectServices.walletRepo,
      modalRepo: this.$projectServices.modalRepo,
      applicationRepo: this.$projectServices.applicationRepo,
      applicationRepository: this.$projectServices.applicationRepository,
      isIncorrectCode: false,
    };
  },
  computed: {
    isCreateDisabled(): boolean {
      return this.code.length !== CODE_MAX_LENGTH || this.isLoading;
    },
  },
  methods: {
    handleClose(): void {
      this.$emit("update:isConfirm", false);
    },

    async handleCreate(): Promise<void> {
      try {
        this.$emit("update:isLoading", true);
        this.isIncorrectCode = false;

        await this.applicationRepository.verification(this.code);

        this.applicationRepo.UPDATE_APPLICATION(
          ApplicationStoreRequestFactory()
        );

        await this.walletRepo.wallet(this.userRepo.userInfo.id);

        this.$emit("update:isConfirm", false);

        this.modalRepo.UPDATE_MODAL({
          title: ModalTitle.application,
          label: ModalLabel.applicationExchange,
          icon: ModalIcon.application,
          actionLabel: ModalActionLabel.ok,
          callback: () => {},
        });
      } catch (error) {
        this.isIncorrectCode = true;
        console.log(error);
      } finally {
        this.$emit("update:isLoading", false);
      }
    },
  },
});
