export enum AdminAppicationStatus {
  in_work = "in_work",
  completed = "completed",
  progress = "progress",
  waiting = "waiting",
  reject = "reject",
  pending = "pending",
}

export enum AdminApplicationType {
  deposit = "deposit",
  withdrawal = "withdrawal",
  depositRequisites = "deposit-requisites",
  verificationDeposit = "verification-deposit",
  verificationWithdrawal = "verification-withdrawal",
  verificationWithdrawalRequisites = "verification-withdrawal-requisites",
}
