import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import {
  SharedWindow,
  SharedDate,
  SharedSelect,
  SharedMultiSelect,
} from "@/components/shared";

import { UIButton, UIInput } from "@/components/ui";

import { SharedSelectOption } from "@/components/shared/select/model";
import { SelectOptionFactory } from "@/shared/factory/select";
import { ApplicationStatus, HistoryTab } from "@/shared/constants/enums";
import { AdminCurrencyShowResponse } from "@/shared/repository/modules/admin/currency/repo";
import { HistoryFilterRequest } from "@/shared/constants/interfaces";
import { DepositWithdrawalHistoryResponse } from "@/shared/repository/modules/deposit-withdrawal/repo";
import { ApplicationHistoryResponse } from "@/shared/repository/modules/application/repo";
import { AdminApplicationType } from "@/shared/repository/modules/admin/application/enums";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedWindow,
    SharedDate,
    SharedSelect,
    SharedMultiSelect,
    UIButton,
    UIInput,
  },
  props: {
    history: {
      type: <
        PropType<
          DepositWithdrawalHistoryResponse[] | ApplicationHistoryResponse[]
        >
      >Array,
      default: () => [],
    },
    currency: {
      type: <PropType<AdminCurrencyShowResponse[]>>Array,
      default: () => [],
    },
  },
  emits: {
    "update:history": null,
  },
  data() {
    return {
      isLoading: false,
      historyRepo: this.$projectServices.historyRepo,
      depositWithdrawalRepository:
        this.$projectServices.depositWithdrawalRepository,
      applicationRepository: this.$projectServices.applicationRepository,
    };
  },
  computed: {
    displayedFilter(): HistoryFilterRequest {
      return this.historyRepo.filter;
    },

    displayedTab(): HistoryTab {
      return this.historyRepo.tab;
    },

    displayedCurrencyOptions(): SharedSelectOption[] {
      return this.currency.map((currency) =>
        SelectOptionFactory({
          id: currency.id,
          label: currency.name,
          value: currency.id,
          icon: currency.country,
        })
      );
    },

    displayedCurrencySelected(): number[] {
      return this.displayedFilter.currency_id
        ? this.displayedFilter.currency_id.slice(1, -1).split(",").map(Number)
        : [];
    },

    displayedStatusSelected(): string[] {
      return this.displayedFilter.status
        ? this.displayedFilter.status.slice(1, -1).split(",").map(String)
        : [];
    },

    displayedTypeSelected(): string[] {
      return this.displayedFilter.type
        ? this.displayedFilter.type.slice(1, -1).split(",").map(String)
        : [];
    },

    displayedStatusOptions(): SharedSelectOption[] {
      return [
        SelectOptionFactory(
          {
            id: 0,
            label: "Создано",
            value: ApplicationStatus.pending,
          },
          this.displayedFilter.status
        ),
        SelectOptionFactory(
          {
            id: 1,
            label: "Ожидание подтверждения",
            value: ApplicationStatus.waiting,
          },
          this.displayedFilter.status
        ),
        SelectOptionFactory(
          {
            id: 2,
            label: "В работе",
            value: ApplicationStatus.inWork,
          },
          this.displayedFilter.status
        ),
        SelectOptionFactory(
          {
            id: 3,
            label: "Отклонено",
            value: ApplicationStatus.reject,
          },
          this.displayedFilter.status
        ),
        SelectOptionFactory(
          {
            id: 4,
            label: "Выполнено",
            value: ApplicationStatus.completed,
          },
          this.displayedFilter.status
        ),
      ];
    },

    displayedTypeOptions(): SharedSelectOption[] {
      return [
        SelectOptionFactory(
          {
            id: 0,
            label: "Пополнение",
            value: AdminApplicationType.deposit,
          },
          this.displayedFilter.type
        ),
        SelectOptionFactory(
          {
            id: 1,
            label: "Вывод",
            value: AdminApplicationType.withdrawal,
          },
          this.displayedFilter.type
        ),
      ];
    },

    isDisabled(): boolean {
      return this.isLoading;
    },

    isDepositWithdrawal(): boolean {
      return this.displayedTab === HistoryTab.depositWithdrawal;
    },
  },
  methods: {
    handleCurrency(ids: number[]): void {
      this.historyRepo.UPDATE_FILTER({
        ...this.displayedFilter,
        currency_id: ids.length ? `[${ids.join(",")}]` : "",
      });
    },

    handleAmountFrom(amount_from: number): void {
      this.historyRepo.UPDATE_FILTER({
        ...this.displayedFilter,
        amount_from,
      });
    },

    handleAmountTo(amount_to: number): void {
      this.historyRepo.UPDATE_FILTER({
        ...this.displayedFilter,
        amount_to,
      });
    },

    handleDateFrom(date_from: string): void {
      this.historyRepo.UPDATE_FILTER({
        ...this.displayedFilter,
        date_from,
      });
    },

    handleDateTo(date_to: string): void {
      this.historyRepo.UPDATE_FILTER({
        ...this.displayedFilter,
        date_to,
      });
    },

    handleStatus(statuses: ApplicationStatus[]): void {
      this.historyRepo.UPDATE_FILTER({
        ...this.displayedFilter,
        status: statuses.length ? `[${statuses.join(",")}]` : "",
      });
    },

    handleType(types: AdminApplicationType[]): void {
      this.historyRepo.UPDATE_FILTER({
        ...this.displayedFilter,
        type: types.length ? `[${types.join(",")}]` : "",
      });
    },

    handleClose(): void {
      this.historyRepo.UPDATE_IS_FILTER(false);
    },

    async handleApply(): Promise<void> {
      try {
        this.historyRepo.CLEAR_PAGE();

        this.isLoading = true;

        let history:
          | DepositWithdrawalHistoryResponse[]
          | ApplicationHistoryResponse[] = [];

        switch (this.displayedTab) {
          case HistoryTab.depositWithdrawal:
            history = await this.depositWithdrawalRepository.history(
              this.displayedFilter
            );

            break;
          case HistoryTab.application:
            history = await this.applicationRepository.history(
              this.displayedFilter
            );

            break;
          default:
            break;
        }

        this.$emit("update:history", history);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;

        this.historyRepo.UPDATE_IS_FILTER(false);
      }
    },
  },
});
