<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()" v-click-outside="handleHide">
    <button :class="b('toggle')" @click="handleToggle">
      <p :class="b('toggle-label')" v-if="isLabelShow">
        {{ placeholder }}
      </p>

      <span :class="b('toggle-values')" v-else>
        <span
          :class="b('toggle-value')"
          v-for="value in displayedValues"
          :key="value.id"
        >
          <span
            v-if="value.icon"
            :class="[b('toggle-icon'), 'fi', `fi-${value.icon}`]"
          ></span>

          {{ value.label }}

          <button
            :class="b('toggle-remove')"
            @click.stop="handleRemoveValue(value)"
          >
            <span :class="b('toggle-icon')" v-html="displayedRemoveIcon"></span>
          </button>
        </span>
      </span>

      <span :class="b('toggle-group')">
        <span :class="b('toggle-counter')" v-if="isCounterShow">
          {{ displayedCounter }}
        </span>

        <span
          :class="b('toggle-icon', { active: isVisible })"
          v-html="displayedChevronIcon"
        ></span>
      </span>
    </button>

    <div :class="b('options', { active: isVisible })">
      <button
        :class="b('option', { active: values.includes(option[label]) })"
        v-for="option in options"
        :key="option.id"
        @click="handleOption(option)"
      >
        <span :class="[b('option-icon'), 'fi', `fi-${option.icon}`]"></span>

        {{ option.label }}
      </button>
    </div>
  </div>
</template>
