import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { SharedMultiSelectOption } from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    values: {
      type: <PropType<number[]>>Array,
      default: () => [],
    },
    options: {
      type: <PropType<SharedMultiSelectOption[]>>Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "id",
    },
  },
  emits: {
    "update:values": null,
  },
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    displayedValues(): SharedMultiSelectOption[] {
      return this.values.map((value) => {
        const findOption = this.options.find(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          (option) => option[this.label] === value
        );

        return {
          id: value,
          icon: findOption?.icon ?? "",
          label: findOption?.label ?? "",
          isActive: false,
          value,
        };
      });
    },

    displayedCounter(): number {
      return this.values.length;
    },

    displayedRemoveIcon(): string {
      return SVG.remove;
    },

    displayedChevronIcon(): string {
      return SVG.chevron;
    },

    isLabelShow(): boolean {
      return !this.values.length;
    },

    isCounterShow(): boolean {
      return !!this.values.length;
    },
  },
  methods: {
    handleToggle(): void {
      this.isVisible = !this.isVisible;
    },

    handleHide(): void {
      this.isVisible = false;
    },

    handleRemoveValue(value: SharedMultiSelectOption): void {
      this.handleRemoveOption(value);
    },

    handleRemoveOption(option: SharedMultiSelectOption): void {
      this.$emit(
        "update:values",
        this.values.filter((value) => value !== option.id)
      );
    },

    handleOption(option: SharedMultiSelectOption): void {
      const hasOption = this.values.find((value) => value === option.value);

      if (hasOption) {
        this.handleRemoveOption(option);
      } else {
        this.$emit("update:values", [...this.values, option.value]);
      }
    },
  },
});
