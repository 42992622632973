import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('row-currency'))
    }, [
      (_ctx.row.type === 'fiat')
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass([
          _ctx.b('row-icon'),
          'fi',
          `fi-${_ctx.row.currency ? _ctx.row.currency.country : _ctx.row.country}`,
        ])
          }, null, 2))
        : (_ctx.row.type === 'crypto' && _ctx.row.name)
          ? (_openBlock(), _createElementBlock("img", {
              key: 1,
              class: _normalizeClass(_ctx.b('row-icon', { crypto: true })),
              src: require(`@/assets/images/crypto/${_ctx.row.name.toLowerCase()}.svg`),
              alt: ""
            }, null, 10, _hoisted_1))
          : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('row-currency-group'))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.b('row-currency-label'))
        }, _toDisplayString(_ctx.row.currency ? _ctx.row.currency.name : _ctx.row.name.toUpperCase()), 3),
        _createElementVNode("span", {
          class: _normalizeClass(
                _ctx.b('row-currency-tag', {
                  bank: (_ctx.row.type as string) === 'fiat',
                  crypto: (_ctx.row.type as string) === 'crypto',
                })
              )
        }, _toDisplayString((_ctx.row.type as string) === "fiat" ? "Bank Account" : "Crypto Account"), 3)
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('row-total'))
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.b('row-total-label'))
      }, _toDisplayString(_ctx.displayedAmount), 3)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('row-actions'))
    }, [
      _createElementVNode("button", {
        class: _normalizeClass(_ctx.b('row-actions-dots', { active: _ctx.isActive })),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isActive = !_ctx.isActive))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.b('row-action-icon')),
          innerHTML: _ctx.displayedIcons.dots
        }, null, 10, _hoisted_2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('row-actions-list', { visible: _ctx.isActive }))
      }, [
        (_ctx.row.type === 'fiat' && _ctx.row.amount > 0)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: _normalizeClass(_ctx.b('row-action')),
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleWithdrawal && _ctx.handleWithdrawal(...args)))
            }, [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.b('row-action-icon')),
                innerHTML: _ctx.displayedIcons.withdrawalGray
              }, null, 10, _hoisted_3)
            ], 2))
          : _createCommentVNode("", true),
        (_ctx.row.type === 'fiat')
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: _normalizeClass(_ctx.b('row-action')),
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleDeposit && _ctx.handleDeposit(...args)))
            }, [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.b('row-action-icon')),
                innerHTML: _ctx.displayedIcons.depositGray
              }, null, 10, _hoisted_4)
            ], 2))
          : _createCommentVNode("", true),
        (_ctx.row.amount > 0)
          ? (_openBlock(), _createElementBlock("button", {
              key: 2,
              class: _normalizeClass(_ctx.b('row-action')),
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleApplication && _ctx.handleApplication(...args)))
            }, [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.b('row-action-icon')),
                innerHTML: _ctx.displayedIcons.swapGray
              }, null, 10, _hoisted_5)
            ], 2))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          class: _normalizeClass(_ctx.b('row-action')),
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleHistory && _ctx.handleHistory(...args)))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.b('row-action-icon')),
            innerHTML: _ctx.displayedIcons.historyGray
          }, null, 10, _hoisted_6)
        ], 2)
      ], 2)
    ], 2)
  ], 2))
}