<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('header')">
      <div :class="b('tabs')">
        <button
          :class="b('tab', { active: tab.active })"
          v-for="tab in displayedTabs"
          :key="tab.id"
          @click="handleTab(tab.value)"
        >
          {{ $t(tab.label) }}
        </button>
      </div>

      <div :class="b('actions')">
        <button
          v-if="displayedWithdrawalButton"
          :class="b('action-small')"
          @click="handleWithdrawal"
        >
          <span
            :class="b('action-icon', { withdrawal: true })"
            v-html="displayedIcons.withdrawal"
          ></span>
        </button>

        <button :class="b('action-small')" @click="handleDeposit">
          <span
            :class="b('action-icon', { deposit: true })"
            v-html="displayedIcons.deposit"
          ></span>
        </button>

        <SharedButtonIcon
          v-if="displayedWithdrawalButton"
          :class="b('action')"
          view="main-outline"
          @click="handleWithdrawal"
        >
          <template #icon-left>
            <span
              :class="b('action-icon', { withdrawal: true })"
              v-html="displayedIcons.withdrawal"
            ></span>
          </template>

          <template #label>
            {{ $t("Вывод") }}
          </template>
        </SharedButtonIcon>

        <SharedButtonIcon
          :class="b('action')"
          view="main"
          @click="handleDeposit"
        >
          <template #icon-left>
            <span
              :class="b('action-icon', { deposit: true })"
              v-html="displayedIcons.deposit"
            ></span>
          </template>

          <template #label>
            {{ $t("Ввод") }}
          </template>
        </SharedButtonIcon>
      </div>
    </div>

    <WalletMainTable :list="list" />
  </div>
</template>
