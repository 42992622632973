import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import {
  DefaultToolbar,
  DefaultContent,
  DefaultNavbar,
  DefaultModal,
} from "@/components/layouts/default";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    DefaultToolbar,
    DefaultContent,
    DefaultNavbar,
    DefaultModal,
  },
  data() {
    return {
      isModal: false,
      userRepo: this.$projectServices.userRepo,
      userRepository: this.$projectServices.userRepository,
      walletRepo: this.$projectServices.walletRepo,
    };
  },
  async created(): Promise<void> {
    await this.walletRepo.wallet(this.userRepo.userInfo.id);

    if (
      !this.userRepo.userInfo.user_info.user_agreement &&
      this.userRepo.isVerified
    ) {
      this.isModal = true;
    }
  },
  methods: {
    async setUserAgreement(): Promise<void> {
      try {
        await this.userRepository.userAgreement({ user_agreement: true });
        this.isModal = false;
      } catch (error) {
        console.error("Failed to set user agreement:", error);
      }
    },
  },
});
