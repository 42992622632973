import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

enum Label {
  default = "",
  max_length = "Максимальная длина 150 символов",

  // KYC - PERSONAL INFO

  sur_name = "Заполните фамилию",
  name = "Заполните имя",
  gender = "Укажите ваш пол",
  birth_date = "Укажите дату рождения",
  citizenship = "Укажите гражданство​ ",
  passport = "Укажите серию и номер паспорта​",
  passport_date = "Укажите дату выдачи паспорта",
  passport_issued_org = "Укажите Наименование органа выдающего документ​",
  phone = "Укажите номер телефона",
  telegram = "Укажите телеграм для связи",
  "address.country" = "Укажите страну",
  "address.city" = "Укажите город",
  "address.state" = "Укажите штат/область",
  "address.street" = "Укажите улицу",
  "address.office" = "Укажите офис",
  "address.zip" = "Укажите индекс",

  // KYC - DOCUMENTS

  "kyc-personal-documents-passport-selfie" = "Загрузите страницу с фото",
  "kyc-personal-documents-passport-organization" = "Загрузите страницу кем выдан​",
  "kyc-personal-documents-passport-address" = "Загрузите страницу с пропиской",
  "kyc-personal-documents-selfie" = "Загрузите селфи",
  "kyc-personal-documents-questionnaire" = "Загрузите подписанную анкету физического лица​",
  "kyc-personal-documents-personal-data" = "Загрузите подписанное согласие на обработку персональных данных",
  "kyc-personal-documents-declaration" = "Загрузите подписанную декларацию о рисках",
  "kyc-personal-documents-broker" = "Загрузите подписанный брокерский договор",

  // KYB - BASIC INFORMATION

  country = "Укажите в какой стране зарегистрирована ваша организация",
  organisation = "Укажите название вашей организации",
  regional_number = "Укажите ваш регистрационный номер",
  registration_date = "Укажите дату регистрации",
  inn = "Укажите номер налогоплательщика",
  licensed = "Укажите лицензируется ли ваша деятельность​",

  // KYB - REGISTRATION INFORMATION

  organisation_legal_form = "Укажите организационно-правовую форму организации",
  business_area = "Укажите к какой сфере относится ваш бизнес",
  "address.isActual" = "Укажите совпадает ли фактический адрес с адресом регистрации ",
  contactPhone = "Укажите контактный номер",

  // KYB - PARTICIPANT DOCUMENTS

  "kyb-participant-documents-passport-selfie" = "Загрузите страницу с фото​ ",
  "kyb-participant-documents-passport-organization" = "Загрузите страницу кем выдан",
  "kyb-participant-documents-questionnaire" = "Загрузите подписанную анкету физического лица",

  // KYB - DOCUMENTS

  "kyb-documents-registration-company" = "Загрузите сертификат  о регистрации компании",
  "kyb-documents-regulation" = "Загрузите устав",
  "kyb-documents-list-of-shareholders" = "Загрузите список акционеров",
  "kyb-documents-license" = "Загрузите лицензию",
  "kyb-documents-check-addresses" = "Загрузите подтверждение адреса регистрации",
  "kyb-documents-sow" = "Загрузите доказательства источника дохода SoW",
  "kyb-documents-sof" = "Загрузите доказательства источника средств SoF",
  "kyb-documents-inn" = "Загрузите свидетельство ИНН",
  "kyb-documents-record-sheet" = "Загрузите лист записи",
  "kyb-documents-create" = "Загрузите решение о создании/учредительный договор",
  "kyb-documents-update" = "Загрузите изменения в учредительные документы",
  "kyb-documents-purpose" = "Загрузите документ о назначении единоличного исполнительного органа",
  "kyb-documents-finance" = "Загрузите финансовую отчетность (за год/квартал)",
  "kyb-documents-example" = "Загрузите карточку образцов подписей и печатей",
  "kyb-documents-document" = "Загрузите подписанную анкету юридического лица",
  "kyb-documents-beneficiary" = "Загрузите подписанную анкету бенефициарного владельца",

  // REQUISITES - BANK

  account_number = "Укажите номер расчетного счета",
  correspondent_account = "Укажите номер корреспондентского счета",
  bank_identification_code = "Укажите БИК",
  bank_name = "Укажите название банка",
  registration_reason_code = "Укажите КПП",

  // REQUISITES - CRYPTO

  coin = "Укажите монету",
  network = "Укажите сеть",
  address = "Укажите адрес",

  // ADMIN - COMMISSION - EXCHANGE

  mex_commission = "Укажите комиссию MEX",
  moex_commission = "Укажите комиссию MOEX",
  swift = "Укажите SWIFT",
  crypto_exchange_commission = "Укажите комиссию Крипто биржи",
  blockchain_transfers_commission = "Укажите блокчейн перевод",
  volatility = "Укажите волатильность",

  // ADMIN - COMMISSION - DEPOSIT WITHDRAWAL

  deposit_fiat_commission = "Укажите комиссию на ввод (Фиат)",
  withdrawal_fiat_commission = "Укажите комиссию на вывод (Фиат)",
  deposit_crypto_commission = "Укажите комиссию на ввод (Крипто)",
  withdrawal_crypto_commission = "Укажите комиссию на вывод (Крипто)",
}

const MAX_LENGTH = 150;

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    field: {
      type: <PropType<Label>>String,
      required: true,
    },
    fieldValue: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number, Boolean],
      required: true,
    },
    isValidate: {
      type: Boolean,
      default: false,
    },
    isCenter: {
      type: Boolean,
      default: false,
    },
    isAbsolute: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:fieldValue": null,
  },
  watch: {
    value(value: string | number | boolean): void {
      if (value !== null) {
        if (
          (!value.toString().length || value.toString().length > MAX_LENGTH) &&
          typeof value !== "boolean"
        ) {
          this.$emit("update:fieldValue", false);
        } else {
          this.$emit("update:fieldValue", true);
        }
      }
    },
  },
  computed: {
    displayedLabel(): Label {
      if (this.isValidate) {
        const value = this.value !== null ? this.value.toString() : this.value;

        if (value === null || !value.length) {
          return Label[this.field as unknown as keyof typeof Label];
        } else if (value.length > MAX_LENGTH) {
          return Label.max_length;
        }
      }

      return Label.default;
    },
  },
});
