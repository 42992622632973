import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedSelect } from "@/components/shared";
import { UIInput, UIButton } from "@/components/ui";

import { DepositWithdrawalStoreRequestFactory } from "@/shared/repository/modules/deposit-withdrawal/factory";
import { SharedSelectOption } from "@/components/shared/select/model";
import { RequisitesBankResponse } from "@/shared/repository/modules/requisites/repo";
import { AdminCurrencyShowResponse } from "@/shared/repository/modules/admin/currency/repo";
import { AdminCurrencyType } from "@/shared/repository/modules/admin/currency/enums";
import { SelectOptionFactory } from "@/shared/factory/select";
import { AdminApplicationType } from "@/shared/repository/modules/admin/application/enums";
import { WalletModalFactory } from "@/shared/repository/modules/wallet/factory";
import { WalletWithAmountResponse } from "@/shared/repository/modules/wallet/repo";
import { ModalFactory } from "@/shared/factory/modal";
import {
  ModalIcon,
  ModalLabel,
  ModalTitle,
} from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedSelect,
    UIInput,
    UIButton,
  },
  data() {
    return {
      isLoading: false,
      isCurrencyDisabled: false,
      currency: <AdminCurrencyShowResponse[]>[],
      requisites: <RequisitesBankResponse[]>[],
      depositWithdrawalStore: DepositWithdrawalStoreRequestFactory({
        user_id: this.$projectServices.userRepo.userInfo.id,
        currency_id: this.$projectServices.depositWithdrawalRepo.application
          ? this.$projectServices.depositWithdrawalRepo.application.currency_id
          : 0,
        amount: this.$projectServices.depositWithdrawalRepo.application
          ? this.$projectServices.depositWithdrawalRepo.application.amount
          : 0,
      }),
      modalRepo: this.$projectServices.modalRepo,
      walletRepo: this.$projectServices.walletRepo,
      depositWithdrawalRepo: this.$projectServices.depositWithdrawalRepo,
      walletRepository: this.$projectServices.walletRepository,
      requisitesRepository: this.$projectServices.requisitesRepository,
      depositWithdrawalRepository:
        this.$projectServices.depositWithdrawalRepository,
      adminCommissionRepository:
        this.$projectServices.adminCommissionRepository,
      userRepo: this.$projectServices.userRepo,
    };
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;

      if (this.walletRepo.modal.isDisabled) {
        const { currencyId, amount } = this.walletRepo.modal;

        this.depositWithdrawalStore = DepositWithdrawalStoreRequestFactory({
          user_id: this.$projectServices.userRepo.userInfo.id,
          currency_id: currencyId,
          amount,
        });

        this.isCurrencyDisabled = true;
      }

      const [currency, requisites, requisitesAdmin] = await Promise.all([
        this.walletRepository.currency(),
        this.requisitesRepository.bankList(this.userRepo.userInfo.id),
        this.adminCommissionRepository.requisitesList(),
      ]);

      this.currency = currency.filter(
        (item) =>
          item.type === AdminCurrencyType.fiat &&
          !item.frozen &&
          requisitesAdmin.find((requisite) => requisite.currency.id === item.id)
      );
      this.requisites = requisites;
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  },
  beforeUnmount(): void {
    this.depositWithdrawalRepo.CLEAR_APPLICATION();
  },
  computed: {
    displayedRequisiteOptions(): SharedSelectOption[] {
      return this.requisites.map((requisite) =>
        SelectOptionFactory(
          {
            id: requisite.id,
            label: requisite.name,
            value: requisite.id,
          },
          this.depositWithdrawalStore.user_requisite_id
        )
      );
    },

    displayedWalletWithAmount(): WalletWithAmountResponse[] {
      return this.walletRepo.withAmount;
    },

    isWithdrawal(): boolean {
      return this.walletRepo.modal.type === AdminApplicationType.withdrawal;
    },

    displayedCurrentWalletWithAmount(): WalletWithAmountResponse | undefined {
      return this.displayedWalletWithAmount.find((value) => {
        return value.currency_id === this.depositWithdrawalStore.currency_id;
      });
    },

    displayedCurrencyOptions(): SharedSelectOption[] {
      return this.currency.map((currency) =>
        SelectOptionFactory(
          {
            id: currency.id,
            label: currency.name.toUpperCase(),
            value: currency.id,
            icon: currency.country,
          },
          this.depositWithdrawalStore.currency_id
        )
      );
    },

    displayedCurrencyIcon(): string {
      return (
        this.currency.find(
          (currency) => currency.id === this.depositWithdrawalStore.currency_id
        )?.country ?? ""
      );
    },

    isDisabled(): boolean {
      const { user_requisite_id, currency_id, amount } =
        this.depositWithdrawalStore;

      return !user_requisite_id || !currency_id || !amount || this.isLoading;
    },
  },
  methods: {
    async handleCreate(): Promise<void> {
      try {
        this.isLoading = true;

        const depositWithdrawal = await this.depositWithdrawalRepository.store(
          this.walletRepo.modal.type as AdminApplicationType,
          AdminCurrencyType.fiat,
          this.depositWithdrawalStore
        );

        if (this.walletRepo.modal.type === AdminApplicationType.deposit) {
          this.walletRepo.UPDATE_MODAL(WalletModalFactory());
          this.depositWithdrawalRepo.UPDATE_DEPOSIT({
            id: depositWithdrawal.id,
            currency_id: depositWithdrawal.currency_id,
            amount: depositWithdrawal.amount,
            currencyName:
              this.currency.find(
                (item) => item.id === depositWithdrawal.currency_id
              )?.name ?? "",
          });
        } else if (
          this.walletRepo.modal.type === AdminApplicationType.withdrawal
        ) {
          this.walletRepo.UPDATE_MODAL(
            WalletModalFactory({
              type: AdminApplicationType.verificationWithdrawal,
            })
          );
        } else {
          this.walletRepo.UPDATE_MODAL(WalletModalFactory());
          this.modalRepo.UPDATE_MODAL(
            ModalFactory({
              title: ModalTitle.application,
              label: ModalLabel.applicationWithdrawal,
              icon: ModalIcon.application,
              callback: () => {},
            })
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
