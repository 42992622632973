import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { UIButton } from "@/components/ui";
import {
  SharedCurrency,
  SharedCryptoSelect,
  SharedSelect,
} from "@/components/shared";

import { SharedCryptoSelectOption } from "@/shared/constants/constants";
import { ApplicationStoreRequest } from "@/shared/repository/modules/application/repo";
import { AdminCurrencyType } from "@/shared/repository/modules/admin/currency/enums";
import {
  WalletListResponse,
  WalletWithAmountResponse,
} from "@/shared/repository/modules/wallet/repo";
import { ApplicationCommissionRequestFactory } from "@/shared/repository/modules/application/factory";
import { ApplicationComponent } from "@/views/application/model";
import { RequisitesCryptoResponse } from "@/shared/repository/modules/requisites/repo";
import { SharedSelectOption } from "@/components/shared/select/model";
import { SelectOptionFactory } from "@/shared/factory/select";
import { formatFiatCurrency } from "@/shared/utils/amount-helpers";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIButton,
    SharedCurrency,
    SharedCryptoSelect,
    SharedSelect,
  },
  props: {
    requisites: {
      type: <PropType<RequisitesCryptoResponse[]>>Array,
      default: () => [],
    },
    component: {
      type: <PropType<ApplicationComponent>>String,
      default: ApplicationComponent.form,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isResult: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:component": null,
    "update:isLoading": null,
    "update:isResult": null,
  },
  data() {
    return {
      abortController: <AbortController | undefined>undefined,
      userRepo: this.$projectServices.userRepo,
      walletRepo: this.$projectServices.walletRepo,
      applicationRepo: this.$projectServices.applicationRepo,
      applicationRepository: this.$projectServices.applicationRepository,
    };
  },
  watch: {
    async "displayedApplicationStoreRequest.currency_id_from"() {
      await this.handleCommission();
    },

    async "displayedApplicationStoreRequest.currency_id_to"() {
      await this.handleCommission();
    },

    async "displayedApplicationStoreRequest.amount"() {
      await this.handleCommission();
    },
  },
  computed: {
    displayedWalletList(): WalletListResponse[] {
      return this.walletRepo.list;
    },

    displayedWalletWithAmount(): WalletWithAmountResponse[] {
      return this.walletRepo.withAmount;
    },

    displayedApplicationStoreRequest(): ApplicationStoreRequest {
      return this.applicationRepo.application;
    },

    displayedCurrencyFrom():
      | WalletWithAmountResponse
      | WalletListResponse
      | undefined {
      const { currency_id_from, currency_type_from } =
        this.displayedApplicationStoreRequest;

      return currency_type_from === "crypto"
        ? this.displayedWalletList.find(
            (currencyItem) => currencyItem.currency_id === currency_id_from
          )
        : this.displayedWalletWithAmount.find(
            (currencyItem) => currencyItem.currency.id === currency_id_from
          );
    },

    displayedCurrencyTo(): WalletListResponse | undefined {
      const { currency_id_to } = this.displayedApplicationStoreRequest;

      return this.displayedWalletList.find(
        (currencyItem) => currencyItem.currency_id === currency_id_to
      );
    },

    displayedFromOptions(): SharedCryptoSelectOption[] {
      return [
        ...this.displayedWalletWithAmount
          .filter((currency) => {
            const { currency_id_to } = this.displayedApplicationStoreRequest;

            if (currency_id_to) return currency.currency_id !== currency_id_to;

            return currency;
          })
          .map((currency) => ({
            id: currency.currency_id,
            label:
              currency.currency.type === AdminCurrencyType.fiat
                ? currency.amount.toString()
                : "",
            currency: {
              fiatIcon:
                currency.currency.type === AdminCurrencyType.fiat
                  ? currency.currency.country
                  : "",
              cryptoIcon:
                currency.currency.type === AdminCurrencyType.crypto
                  ? currency.currency.name
                  : "",
              name: currency.currency.name,
              type: currency.currency.type,
            },
            maxAmount: currency.amount,
            isActive:
              this.displayedApplicationStoreRequest.currency_id_from ===
              currency.currency_id,
          })),
        ...this.displayedWalletList
          .filter((item) => item.type === AdminCurrencyType.crypto)
          .filter((currency) => {
            const { currency_id_to } = this.displayedApplicationStoreRequest;

            if (currency_id_to) return currency.currency_id !== currency_id_to;

            return currency;
          })
          .map((currency) => ({
            id: currency.currency_id,
            label:
              currency.type === AdminCurrencyType.fiat
                ? currency.amount.toString()
                : "",
            currency: {
              fiatIcon:
                currency.type === AdminCurrencyType.fiat && currency.currency
                  ? currency.currency.country
                  : currency.country,
              cryptoIcon:
                currency.type === AdminCurrencyType.crypto ? currency.name : "",
              name: currency.currency ? currency.currency.name : currency.name,
              type: currency.type,
            },
            maxAmount: 0,
            isActive:
              this.displayedApplicationStoreRequest.currency_id_from ===
              currency.currency_id,
          })),
      ];
    },

    displayedToOptions(): SharedCryptoSelectOption[] {
      return this.displayedWalletList
        .filter((currency) => {
          const { currency_id_from } = this.displayedApplicationStoreRequest;

          if (currency_id_from)
            return currency.currency_id !== currency_id_from;

          return currency;
        })
        .map((currency) => ({
          id: currency.currency_id,
          label:
            currency.type === AdminCurrencyType.fiat
              ? currency.amount.toString()
              : "",
          currency: {
            fiatIcon:
              currency.type === AdminCurrencyType.fiat && currency.currency
                ? currency.currency.country
                : currency.country,
            cryptoIcon:
              currency.type === AdminCurrencyType.crypto ? currency.name : "",
            name: currency.currency ? currency.currency.name : currency.name,
            type: currency.type,
          },
          maxAmount: 0,
          isActive:
            this.displayedApplicationStoreRequest.currency_id_to ===
            currency.currency_id,
        }));
    },

    displayedRequisitesFromOptions(): SharedSelectOption[] {
      return this.requisites.map((requisite) =>
        SelectOptionFactory(
          {
            id: requisite.id,
            label: requisite.name,
            value: requisite.id,
          },
          this.displayedApplicationStoreRequest.user_requisite_id_from!
        )
      );
    },

    displayedRequisitesToOptions(): SharedSelectOption[] {
      return this.requisites.map((requisite) =>
        SelectOptionFactory(
          {
            id: requisite.id,
            label: requisite.name,
            value: requisite.id,
          },
          this.displayedApplicationStoreRequest.user_requisite_id_to!
        )
      );
    },

    displayedArrowIcon(): string {
      return SVG.arrow;
    },

    displayedCourse(): string {
      return formatFiatCurrency(
        this.displayedApplicationStoreRequest.execution_price
      );
    },

    displayedCommission(): string {
      return formatFiatCurrency(
        this.displayedApplicationStoreRequest.commissions
      );
    },

    displayedAmount(): string {
      const { amount, commissions, execution_price } =
        this.displayedApplicationStoreRequest;

      return formatFiatCurrency((amount - commissions) * execution_price);
    },

    displayedCurrencyName(): string {
      return this.displayedCurrencyFrom
        ? this.displayedCurrencyFrom.currency?.name ||
            (this.displayedCurrencyFrom as WalletListResponse).name
        : "";
    },

    isRequisitesFromShow(): boolean {
      return (
        !!this.displayedCurrencyFrom &&
        "type" in this.displayedCurrencyFrom &&
        this.displayedCurrencyFrom.type === AdminCurrencyType.crypto
      );
    },

    isRequisitesToShow(): boolean {
      return (
        !!this.displayedCurrencyTo &&
        this.displayedCurrencyTo?.type === AdminCurrencyType.crypto
      );
    },

    isCommissionsShow(): boolean {
      const { commissions, execution_price, transaction_volume } =
        this.displayedApplicationStoreRequest;

      return !!commissions && !!execution_price && !!transaction_volume;
    },

    isNextDisabled(): boolean {
      const {
        currency_id_from,
        currency_id_to,
        currency_type_from,
        currency_type_to,
        amount,
        max_amount,
        user_requisite_id_from,
        user_requisite_id_to,
        execution_price,
        transaction_volume,
        commissions,
      } = this.displayedApplicationStoreRequest;

      const isDisabled =
        !currency_id_from ||
        !currency_id_to ||
        !currency_type_from ||
        !currency_type_to ||
        !amount ||
        amount > max_amount ||
        !execution_price ||
        !transaction_volume ||
        !commissions ||
        this.isLoading;

      if (
        currency_type_from === AdminCurrencyType.fiat &&
        currency_type_to === AdminCurrencyType.fiat
      ) {
        return isDisabled;
      }

      if (
        currency_type_from === AdminCurrencyType.crypto ||
        currency_type_to === AdminCurrencyType.fiat
      ) {
        return isDisabled || !user_requisite_id_from;
      }

      if (
        currency_type_from === AdminCurrencyType.fiat ||
        currency_type_to === AdminCurrencyType.crypto
      ) {
        return isDisabled || !user_requisite_id_to;
      }

      if (
        currency_type_from === AdminCurrencyType.crypto ||
        currency_type_to === AdminCurrencyType.crypto
      ) {
        return isDisabled || !user_requisite_id_from || !user_requisite_id_to;
      }

      return isDisabled;
    },
  },
  methods: {
    async handleCurrencyIdFrom(currency_id_from: number): Promise<void> {
      try {
        this.$emit("update:isLoading", true);

        const option = this.displayedFromOptions.find(
          (option) => option.id === currency_id_from
        )!;

        this.applicationRepo.UPDATE_APPLICATION({
          ...this.displayedApplicationStoreRequest,
          currency_id_from,
          currency_type_from: option.currency.type,
          user_requisite_id_from: 0,
          max_amount: option.maxAmount,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.$emit("update:isLoading", false);
      }
    },

    async handleCurrencyIdTo(currency_id_to: number): Promise<void> {
      try {
        this.$emit("update:isLoading", true);

        const option = this.displayedToOptions.find(
          (option) => option.id === currency_id_to
        )!;

        this.applicationRepo.UPDATE_APPLICATION({
          ...this.displayedApplicationStoreRequest,
          currency_id_to,
          currency_type_to: option?.currency.type,
          user_requisite_id_to: 0,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.$emit("update:isLoading", false);
      }
    },

    handleAmount(amount: number): void {
      this.applicationRepo.UPDATE_APPLICATION({
        ...this.displayedApplicationStoreRequest,
        amount,
      });
    },

    handleMax(): void {
      const { currency_id_from } = this.displayedApplicationStoreRequest;

      this.applicationRepo.UPDATE_APPLICATION({
        ...this.displayedApplicationStoreRequest,
        amount: currency_id_from
          ? Number(
              this.displayedFromOptions.find(
                (option) => option.id === currency_id_from
              )?.label ?? 0
            )
          : 0,
      });
    },

    async handleCommission(): Promise<void> {
      try {
        this.abortController?.abort();

        this.abortController = new AbortController();

        this.applicationRepo.UPDATE_APPLICATION({
          ...this.displayedApplicationStoreRequest,
          commissions: 0,
          execution_price: 0,
          transaction_volume: 0,
        });

        const {
          currency_id_from,
          currency_id_to,
          currency_type_from,
          currency_type_to,
          amount,
        } = this.displayedApplicationStoreRequest;

        if (
          !currency_id_from ||
          !currency_id_to ||
          !currency_type_from ||
          !currency_type_to ||
          !amount
        )
          return;

        const { execute_price, transaction_volume, commission } =
          await this.applicationRepository.commission(
            ApplicationCommissionRequestFactory({
              currency_id_from,
              currency_id_to,
              currency_type_from: currency_type_from as AdminCurrencyType,
              currency_type_to: currency_type_to as AdminCurrencyType,
              amount,
            }),
            this.abortController,
            this.userRepo.userInfo.id
          );

        this.applicationRepo.UPDATE_APPLICATION({
          ...this.displayedApplicationStoreRequest,
          commissions: commission,
          execution_price: execute_price,
          transaction_volume,
        });

        this.$emit("update:isLoading", true);
      } catch (error) {
        this.applicationRepo.UPDATE_APPLICATION({
          ...this.displayedApplicationStoreRequest,
          commissions: 0,
          execution_price: 0,
          transaction_volume: 0,
        });
      } finally {
        this.$emit("update:isLoading", false);
      }
    },

    handleCryptoCreate(): void {
      this.$emit("update:component", ApplicationComponent.crypto);
    },

    handleNext(): void {
      this.$emit("update:isResult", true);
    },
  },
  created() {
    const currency_id_from = this.displayedCurrencyFrom?.currency_id;

    if (currency_id_from) {
      try {
        const option = this.displayedFromOptions.find(
          (option) => option.id === currency_id_from
        )!;

        this.applicationRepo.UPDATE_APPLICATION({
          ...this.displayedApplicationStoreRequest,
          currency_id_from,
          currency_type_from: option.currency.type,
          user_requisite_id_from: 0,
          max_amount: option.maxAmount,
        });
      } catch (error) {
        console.log(error);
      }
    }
  },
});
