import { FileFor, FileType } from "@/shared/constants/enums";

export enum UserApiRoutes {
  user = "/api/v1/user",
  userInfo = "/api/v1/user-info",
  userAgreement = "/api/v1/user-info/update",
  language = "/api/v1/user-info/lang",

  upload = "/api/v1/user/additional-file/upload",
  delete = "/api/v1/user/additional-file/delete",
  files = "/api/v1/user/additional-file/list",
}

export interface UserProjectUrlGenerator {
  user(): string;
  userInfo(): string;

  upload(fileFor: FileFor, fileType: FileType, participantId?: number): string;
  delete(id: number): string;
  files(userId: number, fileFor: FileFor, participantId?: number): string;
}

export class UserUrlGenerator implements UserProjectUrlGenerator {
  user(): string {
    return UserApiRoutes.user;
  }

  userInfo(): string {
    return UserApiRoutes.userInfo;
  }

  userAgreement(): string {
    return UserApiRoutes.userAgreement;
  }

  language(): string {
    return UserApiRoutes.language;
  }

  upload(fileFor: FileFor, fileType: FileType, participantId?: number): string {
    ("/upload?kyb_participant_id=1&file_for=kyc&type=document");

    if (participantId) {
      return `${UserApiRoutes.upload}?kyb_participant_id=${participantId}&file_for=${fileFor}&type=${fileType}`;
    }

    return `${UserApiRoutes.upload}?file_for=${fileFor}&type=${fileType}`;
  }

  delete(id: number): string {
    return `${UserApiRoutes.delete}?id=${id}`;
  }

  files(userId: number, fileFor: FileFor, participantId?: number): string {
    if (participantId) {
      return `${UserApiRoutes.files}?user_id=${userId}&file_for=${fileFor}&kyb_participant_id=${participantId}`;
    }

    return `${UserApiRoutes.files}?user_id=${userId}&file_for=${fileFor}`;
  }
}
