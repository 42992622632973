import { defineComponent } from "vue";

import { PAGE_NAME, SVG } from "./attributes";

import {
  HistoryHeader,
  HistoryTable,
  HistoryDetails,
  HistoryApplicationDetails,
  HistoryFilters,
  HistoryRemove,
} from "@/components/pages/history";
import { SharedButtonIcon } from "@/components/shared";
import { HistoryTab } from "@/shared/constants/enums";
import { HistoryFilterRequest } from "@/shared/constants/interfaces";
import { DepositWithdrawalHistoryResponse } from "@/shared/repository/modules/deposit-withdrawal/repo";
import { ApplicationHistoryResponse } from "@/shared/repository/modules/application/repo";
import { AdminCurrencyShowResponse } from "@/shared/repository/modules/admin/currency/repo";
import { AdminCurrencyType } from "@/shared/repository/modules/admin/currency/enums";
import { PAGE_SIZE } from "@/shared/constants/constants";

export default defineComponent({
  name: PAGE_NAME,
  components: {
    HistoryHeader,
    HistoryTable,
    HistoryDetails,
    HistoryApplicationDetails,
    HistoryFilters,
    HistoryRemove,
    SharedButtonIcon,
  },
  data() {
    return {
      isLoading: false,
      depositWithdrawal: <DepositWithdrawalHistoryResponse | null>null,
      application: <ApplicationHistoryResponse | null>null,
      removeId: <number>0,
      history: <
        DepositWithdrawalHistoryResponse[] | ApplicationHistoryResponse[]
      >[],
      currency: <AdminCurrencyShowResponse[]>[],
      historyRepo: this.$projectServices.historyRepo,
      depositWithdrawalRepo: this.$projectServices.depositWithdrawalRepo,
      walletRepository: this.$projectServices.walletRepository,
      depositWithdrawalRepository:
        this.$projectServices.depositWithdrawalRepository,
      applicationRepository: this.$projectServices.applicationRepository,
    };
  },
  async created(): Promise<void> {
    this.historyRepo.CLEAR_PAGE();

    const [currency] = await Promise.all([
      this.walletRepository.currency(),
      this.handleFetch(),
    ]);

    this.currency = currency.filter(
      (currency) => currency.type === AdminCurrencyType.fiat
    );

    await this.handleFetch();
  },
  watch: {
    displayedTab() {
      this.history = [];
    },
  },
  computed: {
    displayedMoreIcon(): string {
      return SVG.more;
    },

    displayedFilter(): HistoryFilterRequest {
      return this.historyRepo.filter;
    },

    displayedTab(): HistoryTab {
      return this.historyRepo.tab;
    },

    isFilter(): boolean {
      return this.historyRepo.isFilter;
    },

    isMoreShow(): boolean {
      return this.history.length === this.displayedFilter.page * PAGE_SIZE;
    },

    isConfirmShow(): boolean {
      return !!this.removeId && this.removeId !== 0;
    },
  },
  methods: {
    handleDepositWithdrawal(
      depositWithdrawal: DepositWithdrawalHistoryResponse | null
    ): void {
      this.depositWithdrawal = depositWithdrawal;
    },

    handleApplication(application: ApplicationHistoryResponse | null): void {
      this.application = application;
    },

    handleConfirm(id: number = 0): void {
      this.removeId = id;
    },

    async handleRemove(): Promise<void> {
      switch (this.displayedTab) {
        case HistoryTab.depositWithdrawal:
          try {
            await this.depositWithdrawalRepository.delete(this.removeId);
          } catch (error) {
            console.log(error);
          } finally {
            this.handleFetch(false);
            this.handleConfirm();
          }

          break;
        case HistoryTab.application:
          try {
            await this.applicationRepository.delete(this.removeId);
          } catch (error) {
            console.log(error);
          } finally {
            this.handleFetch(false);
            this.handleConfirm();
          }

          break;
        default:
          break;
      }
    },

    async handleFetch(isMore: boolean = false): Promise<void> {
      try {
        this.isLoading = true;

        if (!isMore) {
          this.history = [];
        }

        let history:
          | DepositWithdrawalHistoryResponse[]
          | ApplicationHistoryResponse[] = [];

        switch (this.displayedTab) {
          case HistoryTab.depositWithdrawal:
            history = await this.depositWithdrawalRepository.history(
              this.displayedFilter
            );

            break;
          case HistoryTab.application:
            history = await this.applicationRepository.history(
              this.displayedFilter
            );

            break;
          default:
            break;
        }

        if (isMore) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.history = [...this.history, ...history];
        } else {
          this.history = history;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    async handleMore(): Promise<void> {
      this.historyRepo.ADD_PAGE();

      await this.handleFetch(true);
    },
  },
});
