import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { WalletMainTable } from "./table";
import { SharedButtonIcon } from "@/components/shared";

import { SvgAttribute } from "@/shared/constants/constants";
import { WalletFilterType } from "@/shared/repository/modules/wallet/enums";
import { WalletModalFactory } from "@/shared/repository/modules/wallet/factory";
import { AdminApplicationType } from "@/shared/repository/modules/admin/application/enums";
import { WalletListResponse } from "@/shared/repository/modules/wallet/repo";
import { AdminCurrencyType } from "@/shared/repository/modules/admin/currency/enums";

export interface WalletMainTab {
  id: number;
  label: string;
  value: WalletFilterType;
  active: boolean;
}

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    WalletMainTable,
    SharedButtonIcon,
  },
  data() {
    return {
      tab: WalletFilterType.all,
      list: <WalletListResponse[]>[],
      userRepo: this.$projectServices.userRepo,
      walletRepo: this.$projectServices.walletRepo,
      walletRepository: this.$projectServices.walletRepository,
      adminCommissionRepository:
        this.$projectServices.adminCommissionRepository,
    };
  },
  async created(): Promise<void> {
    await this.handleTab(WalletFilterType.all);
  },
  computed: {
    displayedTabs(): WalletMainTab[] {
      return [
        {
          id: 0,
          label: "all",
          value: WalletFilterType.all,
          active: this.tab === WalletFilterType.all,
        },
        {
          id: 1,
          label: "fiat",
          value: WalletFilterType.fiat,
          active: this.tab === WalletFilterType.fiat,
        },
        {
          id: 2,
          label: "crypto",
          value: WalletFilterType.crypto,
          active: this.tab === WalletFilterType.crypto,
        },
      ];
    },

    displayedTotalSum(): number {
      return this.list.reduce((accumulator, value) => {
        return accumulator + value.amount;
      }, 0);
    },

    displayedWithdrawalButton(): boolean {
      return this.displayedTotalSum > 0;
    },

    displayedIcons(): SvgAttribute {
      return SVG;
    },
  },
  methods: {
    async handleTab(tab: WalletFilterType): Promise<void> {
      this.tab = tab;

      const [list, requisites] = await Promise.all([
        this.walletRepository.list(tab, this.userRepo.userInfo.id),
        this.adminCommissionRepository.requisitesList(),
      ]);

      this.list = list.filter((item) => {
        if (item.type === AdminCurrencyType.crypto) return item;

        return requisites.find(
          (requisite) => requisite.currency.id === item.currency_id
        );
      });
    },

    handleDeposit(): void {
      this.walletRepo.UPDATE_MODAL(
        WalletModalFactory({
          type: AdminApplicationType.deposit,
        })
      );
    },

    handleWithdrawal(): void {
      this.walletRepo.UPDATE_MODAL(
        WalletModalFactory({
          type: AdminApplicationType.withdrawal,
        })
      );
    },
  },
});
