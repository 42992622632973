import { AdminApplicationType } from "../admin/application/enums";
import { AdminCurrencyType } from "../admin/currency/enums";
import { DepositWithdrawalConfirmationRequest } from "./repo";

export enum DepositWithdrawalApiRoutes {
  store = "/api/v1/deposit-withdrawal/store",
  requisite = "/api/v1/deposit/mex-requisite",
  confirmation = "/api/v1/deposit/confirmation",
  show = "/api/v1/deposit-withdrawal/show",
  history = "/api/v1/deposit-withdrawal/app/history",
  delete = "/api/v1/deposit-withdrawal/app/delete",
  verification = "/api/v1/deposit-withdrawal/app/verification",
}

export interface DepositWithdrawalProjectUrlGenerator {
  store(appType: AdminApplicationType, currencyType: AdminCurrencyType): string;
  requisite(currencyId: number): string;
  confirmation(payload: DepositWithdrawalConfirmationRequest): string;
  show(id: number): string;
  history(): string;
  verification(): string;
}

export class DepositWithdrawalUrlGenerator
  implements DepositWithdrawalProjectUrlGenerator
{
  store(
    appType: AdminApplicationType,
    currencyType: AdminCurrencyType
  ): string {
    return `${DepositWithdrawalApiRoutes.store}?app_type=${appType}&currency_type=${currencyType}`;
  }

  requisite(currencyId: number): string {
    return `${DepositWithdrawalApiRoutes.requisite}?currency_id=${currencyId}`;
  }

  confirmation(payload: DepositWithdrawalConfirmationRequest): string {
    return `${DepositWithdrawalApiRoutes.confirmation}?id=${payload.id}&mex_requisite_id=${payload.mex_requisite_id}`;
  }

  show(id: number) {
    return `${DepositWithdrawalApiRoutes.show}?id=${id}`;
  }

  delete(id: number) {
    return `${DepositWithdrawalApiRoutes.delete}?id=${id}`;
  }

  history(): string {
    return DepositWithdrawalApiRoutes.history;
  }

  verification(): string {
    return DepositWithdrawalApiRoutes.verification;
  }
}
