import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DefaultToolbar = _resolveComponent("DefaultToolbar")!
  const _component_DefaultContent = _resolveComponent("DefaultContent")!
  const _component_DefaultNavbar = _resolveComponent("DefaultNavbar")!
  const _component_DefaultModal = _resolveComponent("DefaultModal")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createVNode(_component_DefaultToolbar, {
      class: _normalizeClass(_ctx.b('toolbar'))
    }, null, 8, ["class"]),
    _createVNode(_component_DefaultContent),
    _createVNode(_component_DefaultNavbar, {
      class: _normalizeClass(_ctx.b('navbar'))
    }, null, 8, ["class"]),
    _createVNode(_Transition, { name: "opacity" }, {
      default: _withCtx(() => [
        (_ctx.isModal)
          ? (_openBlock(), _createBlock(_component_DefaultModal, {
              key: 0,
              class: _normalizeClass(_ctx.b('modal')),
              onNext: _ctx.setUserAgreement
            }, null, 8, ["class", "onNext"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}