import moment from "moment";

import { AdminApplicationType } from "../admin/application/enums";
import { AdminCurrencyType } from "../admin/currency/enums";
import { AdminCurrencyShowResponseFactory } from "../admin/currency/factory";
import { AdminRequisitesType } from "../admin/requisites/enums";
import {
  DepositWithdrawalDeposit,
  DepositWithdrawalHistoryResponse,
  DepositWithdrawalShowResponse,
  DepositWithdrawalStoreRequest,
  DepositWithdrawalVerificationRequest,
} from "./repo";

import { ApplicationStatus } from "@/shared/constants/enums";
import { RequisitesBankRequestFactory } from "../requisites/factory";

export const DepositWithdrawalStoreRequestFactory = (
  payload: Partial<DepositWithdrawalStoreRequest> = {},
  isRequisites: boolean = false
): DepositWithdrawalStoreRequest => {
  if (isRequisites) {
    return {
      user_id: payload.user_id ?? 0,
      currency_id: payload.currency_id ?? 0,
      user_requisite_id: payload.user_requisite_id ?? 0,
      user_requisite_type:
        payload.user_requisite_type ?? AdminRequisitesType.bank,
      amount: payload.amount ?? 0,
      new_requisite: RequisitesBankRequestFactory(payload.new_requisite ?? {}),
    };
  }

  return {
    user_id: payload.user_id ?? 0,
    currency_id: payload.currency_id ?? 0,
    user_requisite_id: payload.user_requisite_id ?? 0,
    user_requisite_type:
      payload.user_requisite_type ?? AdminRequisitesType.bank,
    amount: payload.amount ?? 0,
  };
};

export const DepositWithdrawalHistoryResponseFactory = (
  payload: Partial<DepositWithdrawalHistoryResponse> = {}
): DepositWithdrawalHistoryResponse => {
  return {
    id: payload.id ?? 0,
    user_id: payload.user_id ?? 0,
    bank_requisite_id: payload.bank_requisite_id ?? 0,
    mex_requisite_id: payload.mex_requisite_id ?? 0,
    app_type: payload.app_type ?? AdminApplicationType.deposit,
    currency_type: payload.currency_type ?? AdminCurrencyType.fiat,
    currency_id: payload.currency_id ?? 0,
    currency: AdminCurrencyShowResponseFactory(payload.currency ?? {}),
    user_requisite_id: payload.user_requisite_id ?? 0,
    user_requisite_type:
      payload.user_requisite_type ?? AdminRequisitesType.bank,
    amount: Number(payload.amount ?? 0),
    execution_price: payload.execution_price ?? "",
    transaction_volume: payload.transaction_volume ?? "",
    commissions: Number(payload.commissions ?? 0),
    status: payload.status ?? ApplicationStatus.pending,
    stage: payload.stage ?? "",
    deleted_at: payload.deleted_at ?? "",
    created_at: moment(payload.created_at ?? "").format("YYYY-MM-DD HH:mm:ss"),
    updated_at: payload.updated_at ?? "",
  };
};

export const DepositWithdrawalShowResponseFactory = (
  payload: Partial<DepositWithdrawalShowResponse> = {}
): DepositWithdrawalShowResponse => {
  return {
    id: payload.id ?? 0,
    user_id: payload.user_id ?? 0,
    bank_requisite_id: payload.bank_requisite_id ?? 0,
    mex_requisite_id: payload.mex_requisite_id ?? 0,
    app_type: payload.app_type ?? AdminApplicationType.deposit,
    currency_type: payload.currency_type ?? AdminCurrencyType.fiat,
    currency_id: payload.currency_id ?? 0,
    currency: AdminCurrencyShowResponseFactory(payload.currency ?? {}),
    user_requisite_id: payload.user_requisite_id ?? 0,
    user_requisite_type:
      payload.user_requisite_type ?? AdminRequisitesType.bank,
    amount: Number(payload.amount ?? 0),
    execution_price: payload.execution_price ?? "",
    transaction_volume: payload.transaction_volume ?? "",
    commissions: Number(payload.commissions ?? 0),
    status: payload.status ?? ApplicationStatus.pending,
    stage: payload.stage ?? "",
    deleted_at: payload.deleted_at ?? "",
    created_at: moment(payload.created_at ?? "").format("YYYY-MM-DD HH:mm:ss"),
    updated_at: payload.updated_at ?? "",
  };
};

export const DepositWithdrawalDepositFactory = (
  payload: Partial<DepositWithdrawalDeposit> = {}
): DepositWithdrawalDeposit => {
  return {
    id: payload.id ?? 0,
    currency_id: payload.currency_id ?? 0,
    amount: payload.amount ?? 0,
    currencyName: payload.currencyName ?? "",
  };
};

export const DepositWithdrawalVerificationRequestFactory = (
  payload: Partial<DepositWithdrawalVerificationRequest> = {}
): DepositWithdrawalVerificationRequest => {
  return {
    code: payload.code ?? "",
  };
};
